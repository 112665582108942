.enquete-view {
  padding: 20px;
  background-color: #fff;
}

.enquete-view .page-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.enquete-view header {
  font-size: 14px;
  margin-bottom: 20px;
}

.enquete-view header div {
  padding: 10px;
  background: #eee;
  color: #666;
}

.enquete-view header div:first-child {
  font-size: 1.2em;
  border-radius: 10px 10px 0 0;
  background: #fd7e14;
  color: #fff;
}

.enquete-view header div:last-child {
  border-radius: 0 0 10px 10px;
}

.enquete-view header h2 {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
  display: inline-block;
}

.enquete-view header p {
  font-size: 1.1rem;
  font-weight: normal;
  margin: 0;
  margin-left: 10px;
  display: inline-block;
}

/* Estilo Geral da Tabela */
.enquete-view table {
  width: 100%;
  border-collapse: collapse;
}

/* Estilo do Cabe�alho da Tabela */
.enquete-view table thead tr {
  background-color: black !important;
  color: white;
}

/* Estilo das C�lulas da Tabela */
.enquete-view table td,
.enquete-view table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-transform: capitalize;
}

/* Estilo para Linhas Zebradas */
.enquete-view table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

/* Estilo ao passar o mouse sobre uma linha */
.enquete-view table tr:hover {
  background-color: #ddd;
}

.dashboard {
  background-color: #f0f4f8;
  border-radius: 8px;
  margin: 0 auto 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.dashboard h2 {
  padding: 10px;
  text-align: center;
  font-size: 16px;
  background: #fd7e14;
  color: #fff;
  border-radius: 8px 8px 0 0;
  width: 100%;
}

.dashboard .dashboard-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.statistic {
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 48%;
}

.statistic span {
  color: #666;
  font-size: 16px;
}

.statistic strong {
  color: #333;
  font-size: 18px;
}
