/* Overlay .forms-cae */
.forms-cae {
  position: fixed;
  font-size: 16px;
  z-index: 10000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.forms-cae-overlay {
  content: "";
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.85);
}

.forms-cae h1 {
  font-size: 18px;
  margin-bottom: 20px;
  background: #fd7e14;
  padding: 20px;
  text-align: center;
  color: #fff;
  border-radius: 10px 10px 0 0;
  margin: 0;
}

.forms-cae h6 {
  font-weight: normal;
  font-size: 14px;
  margin: 0px;
  padding: 20px;
  text-align: center;
  color: #999;
}

.forms-cae form {
  padding: 20px;
  margin: 0;
}

.forms-cae label {
  display: block;
}

.forms-cae .student-name {
  text-transform: capitalize;
}

.forms-cae .switch {
  position: relative;
  display: inline-block;
  width: 80px; /* Ajustado para acomodar os textos */
  height: 34px;
}

.forms-cae .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.forms-cae .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.forms-cae .slider:before, .forms-cae .slider:after {
  position: absolute;
  content: "";
  transition: 0.4s;
}

.forms-cae .slider:before {
  height: 26px;
  width: 36px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 16px;
}

.forms-cae input:checked + .slider {
  background-color: #2196F3;
}

.forms-cae input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.forms-cae input:checked + .slider:before {
  transform: translateX(36px);
}

.forms-cae .slider:after {
  /* Removido para evitar duplica��o e corre��o do texto */
}

/* Estilizando as perguntas para melhor visualiza��o */
.forms-cae div {
  margin-bottom: 20px;
}

/* Ajustes para exibir os textos dentro do switch */
.forms-cae .slider:before {
  font-size: 12px;
  font-weight: bold;
}

.forms-cae input:checked + .slider:before {
  /* Para "SIM", o texto muda quando ativado. */
  content: "SIM"; /* Troque o texto para SIM quando estiver ativo */
  color: white;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  color: #2196F3;
}

/* Texto "N�O" no lado inativo */
.forms-cae input:not(:checked) + .slider:after {
  content: "N�O";
  color: black;
  display: block;
  position: absolute;
  transform: translateX(0);
  top: 4px;
  left: 10px;
  font-size: 12px;
  line-height: 26px;
  font-weight: bold;
  color: #a20d0d;
}

.forms-cae button {
  display: block;
  margin: 40px auto 0;
  background: #0c8a03;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.forms-cae button:hover {
  background: #064d01;
}

.forms-cae button span {
  display: inline-block;
  margin-left: 20px;
}